
import { createContext } from "react";


const CurrentUserContext = createContext();


export { CurrentUserContext };



